import * as React from 'react';
import SbEditable from 'storyblok-react';
import * as PageContentStyles from '../_scss/modules/_page-content.module.scss';
import DynamicComponent from '../components/_common/dynamic-component/dynamic-component';
import Layout from '../components/_common/_layout/layout';
import SEO from '../components/_common/seo/seo';
import HsLink from '../components/_common/hs-link/hs-link';
import { getRichTextMarkup, useStoryBlockCommon, useStoryblok } from '../lib/storyblok/storyblok';

const NotFoundPage = () => {
    // will not work correctly for pages created with home template (header will be missing)
    const path = typeof window !== 'undefined' ? location.pathname.substring(1) : `${process.env.GATSBY_DEFAULT_LANGUAGE}`;
    const storyblokCommon = useStoryBlockCommon(path ?? process.env.GATSBY_COUNTRY ?? '');

    const story = useStoryblok(null);
    let components = null;

    let header;
    if (story?.content.header) {
        header = story.content.header.map((blok: any) => {
            return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
        });
    }

    if (story?.content?.body) {
        components = story.content.body.map((blok: any) => {
            return <DynamicComponent blok={blok} key={blok._uid} storyblokCommon={storyblokCommon} />;
        });
    }

    return (
        <>
            {story ? (
                <SbEditable content={story.content}>
                    <Layout paddingDisabled={true} storyblokCommon={storyblokCommon}>
                        {story.content.metadata && (
                            <SEO
                                metadata={story.content.metadata}
                                config={storyblokCommon.configuration.content}
                                full_slug={`${storyblokCommon.configuration.content.language}/404`}
                            />
                        )}
                        {header}
                        <div className={PageContentStyles.pageContentStandard}>{components}</div>
                    </Layout>
                </SbEditable>
            ) : (
                <Layout paddingDisabled={false} storyblokCommon={storyblokCommon}>
                    <SEO
                        metadata={storyblokCommon.notFound.content.metadata}
                        config={storyblokCommon.configuration.content}
                        full_slug={`${storyblokCommon.configuration.content.language}/404`}
                    />
                    <div>
                        <h4>{storyblokCommon.notFound.content.headline}</h4>
                        <div dangerouslySetInnerHTML={getRichTextMarkup(storyblokCommon.notFound.content.copytext)} />
                        <div style={{ marginTop: '24px' }}>
                            <HsLink to={'/'} storyblokCommon={storyblokCommon}>
                                {storyblokCommon.notFound.content.link_text}
                            </HsLink>
                        </div>
                    </div>
                </Layout>
            )}
        </>
    );
};

export default NotFoundPage;
